<template>
  <div class="feature-item">
    <img :src="icon" alt="icon" />

    <div>
      <h5 style="text-align: center;">{{ title }}</h5>
      <p><slot /></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['icon', 'title'],
}
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .feature-item {
    width: 45%;
  }
}

.feature-item {
  display: flex;
  align-items: center;
}

.feature-item > img {
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
}
</style>
