<template>
  <div class="bg bgg">
    <div class="container immigration-top flex sb">
      <SplashText class="slogan-wrap">
        <div slot="slogan">
          <span class="slotgan-info"
            >End-to-end <span style="color: #F9B54A">AI</span> Paralegal
            Services</span
          >
          <p class="slotgan-info">
            with <span style="color: #F9B54A">Human</span> Oversight
          </p>
        </div>
        <b slot="slogan-text">
          Propel Your Law Firm into the Future: Excel and Expand with AI
        </b>
        <div slot="addon" class="conBtn">
          <ConsultationButton
            text="Schedule Your Demo Today"
            link="https://www.immigrationlaw.ai/activity/ailaw/demo"
          />
        </div>
      </SplashText>
    </div>
  </div>
</template>

<script>
import SplashText from '../../components/Block/SplashText'
import ConsultationButton from '../../components/Block/ConsultationButton'

export default {
  components: { SplashText, ConsultationButton },
  mounted() {
    document.addEventListener('DOMContentLoaded', function() {
      if (window.hideYTActivated) return
      let onYouTubeIframeAPIReadyCallbacks = []
      for (let playerWrap of document.querySelectorAll('.hytPlayerWrap')) {
        let playerFrame = playerWrap.querySelector('iframe')
        let tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        let firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        let onPlayerStateChange = function(event) {
          if (event.data == YT.PlayerState.ENDED) {
            playerWrap.classList.add('ended')
          } else if (event.data == YT.PlayerState.PAUSED) {
            playerWrap.classList.add('paused')
          } else if (event.data == YT.PlayerState.PLAYING) {
            playerWrap.classList.remove('ended')
            playerWrap.classList.remove('paused')
          }
        }
        let player
        onYouTubeIframeAPIReadyCallbacks.push(function() {
          player = new YT.Player(playerFrame, {
            events: { onStateChange: onPlayerStateChange },
          })
        })
        playerWrap.addEventListener('click', function() {
          let playerState = player.getPlayerState()
          if (playerState == YT.PlayerState.ENDED) {
            player.seekTo(0)
          } else if (playerState == YT.PlayerState.PAUSED) {
            player.playVideo()
          }
        })
      }
      window.onYouTubeIframeAPIReady = function() {
        for (let callback of onYouTubeIframeAPIReadyCallbacks) {
          callback()
        }
      }
      window.hideYTActivated = true
    })
  },
}
</script>

<style scoped>
.hytPlayerWrap {
  display: inline-block;
  position: relative;
}

.hytPlayerWrap.ended::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 64px 64px;
  background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgNTEwIDUxMCI+PHBhdGggZD0iTTI1NSAxMDJWMEwxMjcuNSAxMjcuNSAyNTUgMjU1VjE1M2M4NC4xNSAwIDE1MyA2OC44NSAxNTMgMTUzcy02OC44NSAxNTMtMTUzIDE1My0xNTMtNjguODUtMTUzLTE1M0g1MWMwIDExMi4yIDkxLjggMjA0IDIwNCAyMDRzMjA0LTkxLjggMjA0LTIwNC05MS44LTIwNC0yMDQtMjA0eiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==);
}

.hytPlayerWrap.paused::after {
  content: '';
  position: absolute;
  top: 70px;
  left: 0;
  bottom: 50px;
  right: 0;
  cursor: pointer;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 40px;
  background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEiIHdpZHRoPSIxNzA2LjY2NyIgaGVpZ2h0PSIxNzA2LjY2NyIgdmlld0JveD0iMCAwIDEyODAgMTI4MCI+PHBhdGggZD0iTTE1Ny42MzUgMi45ODRMMTI2MC45NzkgNjQwIDE1Ny42MzUgMTI3Ny4wMTZ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+);
}

.bg {
  background-image: url('../../assets/immigration/hero_bg2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: -4.5rem;
  padding-top: 10rem;
  height: 744px;
}

.immigration-top {
  color: #fff;
  padding-bottom: 5rem;
}

.slotgan-info {
  font-size: 2.25rem;
}

.slogan-wrap {
  flex: 1.5;
  flex-direction: column;
  align-items: flex-start;
}

.video-wrap {
  margin-left: 30px;
  flex: 1;
  /* background: url(../../assets/nxt/poster.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 360px;
  width: 640px;
  flex-shrink: 0;
  display: block;
}

.video-wrap .iframe {
  height: 360px;
  width: 640px;
}

.slideLeft {
  animation: slideLeft 0.8s linear;
}

.watchvideo {
  display: none;
  margin-bottom: 20px;
}

@keyframes slideLeft {
  0% {
    opacity: 0.2;
    position: relative;
    right: 100px;
  }

  50% {
    opacity: 0.5;
    position: relative;
    right: 50px;
  }

  100% {
    opacity: 1;
    position: relative;
    right: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .bg {
    background-image: url('../../assets/immigration/hero_bg2.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -4.5rem;
    margin-top: -4.5rem;
    padding-top: 3.5rem;
    padding-bottom: 8rem;
  }

  .bgg {
    padding-top: 5.5rem;
    padding-bottom: 3rem;
  }

  .mtopbar {
    margin-top: 1rem;
  }

  div.immigration-top {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .conBtn {
      margin: 0 auto;
    }
  }

  a.video-wrap {
    flex: none;
    width: 400px;
    height: 225px;
    margin-left: 0px;
  }

  .video-wrap .iframe {
    height: 225px;
    width: 400px;
  }
}

@media (max-width: 850px) {
  span.slotgan-info,
  p.slotgan-info {
    font-size: 2rem;
  }

  div.bg {
    height: auto;
  }

  div.watchvideo {
    display: block;
  }
}

@media (max-width: 750px) {
  span.slotgan-info,
  p.slotgan-info {
    font-size: 1.5rem;
  }

  a.video-wrap {
    flex: none;
    width: 260px;
    height: 146px;
  }

  .video-wrap .iframe {
    height: 146px;
    width: 260px;
  }
}
</style>
