<template>
  <div
    type="button"
    data-toggle="modal"
    :data-target="target"
  >
    <div class="service-offerings" :class="{ clickable: !!pricing }">
      <h5>{{ title }}</h5>
      <p>{{ description }}</p>
    </div>

    <h5 v-if="pricing" >{{ pricing }}</h5>
    <h5 v-else style="color: #F9A842">Coming Soon</h5>

    <slot />
  </div>
</template>

<script>
export default {
  props: ['title', 'description', 'target', 'pricing'],
}
</script>

<style scoped>
.service-offerings {
  background: #00054a;
  color: white;
  padding: 1rem;
  height: 12rem;
  margin-bottom: 0.5rem;
}

.clickable:hover {
  opacity: 0.8;
}

.clickable:active {
  opacity: 0.6;
}
</style>
