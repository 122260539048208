<template>
  <div class="step-item">
    <div class="index">{{ index }}</div>
    <h5 style="margin-top: 1rem">{{ title }}</h5>
    <p><slot /></p>
  </div>
</template>

<script>
export default {
  props: ['index', 'title'],
}
</script>

<style scoped>
.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-item > .index {
  font-size: 1.4rem;
  border: 2px solid currentColor;
  border-radius: 2px;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>