<template>
  <div
    :id="carouselId"
    class="carousel slide"
    data-ride="carousel"
    ref="carousel"
  >
    <ol
      class="carousel-indicators"
      :style="{ visibility: hideIndicators ? 'hidden' : undefined }"
    >
      <li
        v-for="(_, index) in $slots.default"
        ref="indicators"
        :key="index"
        :data-target="'#' + carouselId"
        :data-slide-to="index"
      />
    </ol>
    <div class="carousel-inner">
      <slot></slot>
    </div>
    <a
      class="carousel-control-prev"
      :href="'#' + carouselId"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      :href="'#' + carouselId"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  props: {
    carouselId: {
      type: String,
      required: true,
    },
    hideIndicators: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.$refs.indicators[0].className += 'active'

    this.$slots.default.forEach((s, i) => {
      s.elm.className += [
        '',
        'carousel-item',
        i === 0 ? 'active' : undefined,
      ].join(' ')
    })

    $(this.$refs.carousel).on('slide.bs.carousel', ({ to }) =>
      this.$emit('input', to)
    )
  },

  destroyed() {
    $(this.$refs.carousel).off('slide.bs.carousel')
  },
}
</script>

<style lang="less" scoped>
.carousel {
  display: flex;
}

.carousel-indicators {
  > li {
    background-color: #222;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%222' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%222222' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-inner {
  display: flex;
  align-items: center;
}

&.carousel-item.carousel-item {
  display: none;
  padding: 10%;

  &.carousel-item-next,
  &.carousel-item-prev,
  &.active {
    display: flex;
  }
}
</style>
