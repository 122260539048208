<template>
  <div class="faq-item mw-100 mw-lg-auto">
    <h5>{{ question }}</h5>
    <p><slot /></p>
  </div>
</template>

<script>
export default {
  props: ['question'],
}
</script>

<style scoped>
.faq-item {
  background: white;
  width: 800px;
  height: 28rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
}
</style>
