<template>
  <div>
    <hero-paralegal />

    <div class="section">
      <h2>
        Problems & Challenges for Law Firms
      </h2>

      <div
        id="carouselExampleControls"
        class="carousel slide intro"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active block">
            <b>AI Revolution and Regulatory Adaptation in the Legal Industry</b>
            <p>
              As an attorney, you are at the forefront of a transformative era
              in the legal profession. The advent of AI technology isn't just
              opening up new avenues for efficiency; it's also changing the
              landscape in which traditional legal practices operate. In states
              like Arizona and Utah, groundbreaking regulations now allow
              non-lawyers to own law firms or have financial interests in legal
              services. This shift is catalyzing the emergence of a new breed of
              online law firms, equipped with advanced marketing and AI
              capabilities, ready to fiercely compete with established firms in
              areas governed by federal law, including immigration.
            </p>
          </div>

          <div class="carousel-item block">
            <b>Maintaining Competitiveness Amid Rising Costs</b>
            <p>
              As the legal field evolves, fees for standard cases like H-1B
              applications have stagnated, while operational and personnel
              expenses climb, eroding profit margins. Moreover, staff turnover
              continues to be a challenge, requiring significant time and
              financial resources for recruitment and training.
            </p>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>

    <div class="section">
      <h2>Solution Overview</h2>
      <div class="block">
        <b
          >To navigate and thrive in this new era, law firm owners must
          introspect and act decisively on several fronts:</b
        >
        <div class="sider">
          <div class="illustration">
            <img
              :src="
                require('../../assets/immigration/intelligent-information-storage.png')
              "
              alt="Business Development"
            />

            <div>Business Development</div>
          </div>

          <p style="margin-left: 2rem;">
            Successful law firm owners are, at heart, savvy business individuals
            adept at seizing new opportunities. It's crucial to reassess your
            client development efforts. Are you making the most of seminars,
            leveraging social media to enhance your personal brand, forging
            connections within related industries, refining your referral
            system, personally visiting key clients, and exploring innovative
            marketing channels such as podcasts or webinars tailored to your
            expertise?
          </p>
        </div>

        <div class="sider flex-column-reverse flex-md-row" style="margin-top: 1rem;">
          <p style="margin-right: 2rem;">
            Successful law firm owners are, at heart, savvy business individuals
            adept at seizing new opportunities. It's crucial to reassess your
            client development efforts. Are you making the most of seminars,
            leveraging social media to enhance your personal brand, forging
            connections within related industries, refining your referral
            system, personally visiting key clients, and exploring innovative
            marketing channels such as podcasts or webinars tailored to your
            expertise?
          </p>

          <div class="illustration">
            <img
              :src="require('../../assets/paralegal/paralegal.png')"
              alt="Business Development"
            />

            <div>Client Maintenance</div>
          </div>
        </div>
      </div>
    </div>

    <div class="page">
      <split-carousel :source="splitCarouselSource" />
    </div>

    <div class="section">
      <h2>How to work with us</h2>

      <div class="block">
        <b>A Streamlined Three-Step Process</b>
        <p>
          Follow three simple steps to ensure your case is handled efficiently.
          Once you submit your case request, we'll proactively manage its
          progress—eliminating any concern about missing steps on your part.
        </p>

        <div class="work-with-us flex-column flex-md-row">
          <content-card
            title="Case Request"
            content="Begin by submitting your
          case request. Provide a brief overview of the case, including any
          specific requirements or goals you have in mind."
            :icon="plusIcon"
          />

          <content-card
            title="Confirmation of Key Information"
            content="Upon reviewing your initial request, we will confirm the essential details and request any additional information if needed. This step ensures that both parties are aligned and that our AI tools are primed with all necessary data to proceed effectively."
            :icon="searchIcon"
          />

          <content-card
            title="Final Draft Review and Case Outcome Acceptance"
            content="After our AI Paralegals have prepared the case documents, a senior paralegal will perform a final review to guarantee accuracy and completeness. You will then receive the final draft for approval, marking the conclusion of the service."
            :icon="calendarIcon"
          />
        </div>
      </div>
    </div>

    <div class="section">
      <h2>Features and Advantages</h2>

      <div class="features">
        <feature-item title="No Human Error" :icon="noHumanIcon">
          Tasks are performed by AI to eliminate clerical errors, with close
          monitoring by senior paralegals to ensure flawless execution.
        </feature-item>

        <feature-item title="Efficiency" :icon="efficiencyIcon">
          Significant time savings as AI handles research, drafts initial
          documents, and fills out forms, enabling cases to be processed much
          faster — a crucial advantage for meeting tight deadlines.
        </feature-item>

        <feature-item title="Budget control" :icon="improvementIcon">
          High-quality results are achieved more cost-effectively, allowing for
          better allocation of resources.
        </feature-item>

        <feature-item
          title="Competitive Edge in the AI Era"
          :icon="competitiveIcon"
        >
          With improved efficiency, guaranteed accuracy, and enhanced budget
          control, our service frees up valuable time for you. We encourage you
          to invest this time in client care and business expansion, helping
          your firm grow.
        </feature-item>
      </div>
    </div>

    <div class="section block3">
      <h2>Service Offerings</h2>

      <div class="block">
        <b
          >Below are the case types we currently support and those we plan to
          support in the near future.</b
        >

        <div class="offerings">
          <service-offering-item
            title="H-1B"
            description="Specialty Occupations Visa"
            pricing="$300"
            target="#h-1b-modal"
          >
            <div id="h-1b-modal" class="modal" tabindex="-1" role="dialog">
              <div
                class="modal-dialog modal-lg"
                role="document"
                style="max-width: 1200px"
              >
                <div class="modal-content">
                  <div class="modal-header">
                    <h5
                      class="modal-title"
                      style="text-align: center; width: 100%"
                    >
                      H-1B Case Steps
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="h-1b-modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body offering-modal">
                    <step-item :index="1" title="Case Opening"
                      >Submit your case request via Google Forms.</step-item
                    >

                    <step-item
                      :index="2"
                      title="Verification of Crucial Information"
                      >This step involves addressing any discrepancies in the
                      questionnaire, verifying the highest degree obtained, the
                      field of study, as well as the SOC (Standard Occupational
                      Classification) title, and the prevailing wage. You will
                      receive all necessary supporting information or reasoning
                      to do an easy evaluation.</step-item
                    >

                    <step-item
                      :index="3"
                      title="Review of ETA-9035 Documentation"
                      >Within one business day, you will receive a notification
                      to review the ETA-9035 form on the FLAG system. Once
                      reviewed, please proceed to submit it.</step-item
                    >

                    <step-item
                      :index="4"
                      title="Final Review of Case Documentation"
                      >Before the LCA is approved, you will be notified to
                      review the final draft on myUSCIS. After the approval of
                      LCA, we will promptly notify you to continue with the
                      submission of your H-1B case. Alternatively, you can
                      choose to file the case traditionally by receiving the PDF
                      versions of forms, supporting documents, and petition
                      letters.</step-item
                    >

                    <step-item :index="5" title="Case Progress Monitoring"
                      >We will proactively monitor the progression of your case
                      and provide timely updates until the process is
                      successfully completed.</step-item
                    >
                  </div>
                </div>
              </div>
            </div>
          </service-offering-item>

          <service-offering-item
            title="L-1 Visa"
            description="Intra-company Transferee"
          />

          <service-offering-item
            title="O-1 Visa"
            description="Individuals with Extraordinary Ability or Achievement"
          />

          <service-offering-item title="E-2 Visa" description="Investor" />

          <service-offering-item
            title="EB-1 Immigrant Visa (Green Card)"
            description="Extraordinary Ability"
          />

          <service-offering-item
            title="EB-2 Immigrant Visa (Green Card)"
            description="Advanced Degree or Exceptional Ability"
          />

          <service-offering-item
            title="EB-3 Immigrant Visa (Green Card)"
            description="Skilled Workers, Professionals & Other Workers"
          />

          <service-offering-item
            title="EB-4 Immigrant Visa (Green Card)"
            description="Special Immigrants"
          />
        </div>
      </div>
    </div>

    <div class="section">
      <h2>FAQs</h2>

      <testimonial class="d-none d-lg-block">
        <faq-item slot="left" question="How secure is your AI service?">
          We prioritize data security by employing state-of-the-art encryption
          and adhering to legal industry standards. All data used to train our
          AI consists of general information, ensuring privacy and security are
          never compromised.
        </faq-item>
        <faq-item
          slot="middle"
          question="Can your service integrate with our existing systems?"
        >
          Absolutely. If you're already using Neutrinet case management
          software, any cases processed will automatically appear on your
          dashboard for seamless integration. For users of other systems, we
          offer full case management services via Neutrinet for the cases you
          submit. Final materials can be easily downloaded with just one click
          for upload to your existing case management system. Additionally,
          current Neutrinet software users are entitled to a 5% discount on our
          paralegal services.
        </faq-item>
        <faq-item slot="right" question="How does the AI improve over time?">
          Our AI algorithms are designed to learn from every case interaction,
          constantly evolving to enhance both efficiency and accuracy. This
          continuous learning process ensures that our services improve with
          every use, providing you with the best possible support for your legal
          needs.
        </faq-item>
      </testimonial>

      <carousel carouselId="faqs-carousel" class="d-lg-none">
        <faq-item
          class="carousel-item active"
          question="How secure is your AI service?"
        >
          We prioritize data security by employing state-of-the-art encryption
          and adhering to legal industry standards. All data used to train our
          AI consists of general information, ensuring privacy and security are
          never compromised.
        </faq-item>
        <faq-item
          class="carousel-item"
          question="Can your service integrate with our existing systems?"
        >
          Absolutely. If you're already using Neutrinet case management
          software, any cases processed will automatically appear on your
          dashboard for seamless integration. For users of other systems, we
          offer full case management services via Neutrinet for the cases you
          submit. Final materials can be easily downloaded with just one click
          for upload to your existing case management system. Additionally,
          current Neutrinet software users are entitled to a 5% discount on our
          paralegal services.
        </faq-item>
        <faq-item
          class="carousel-item"
          question="How does the AI improve over time?"
        >
          Our AI algorithms are designed to learn from every case interaction,
          constantly evolving to enhance both efficiency and accuracy. This
          continuous learning process ensures that our services improve with
          every use, providing you with the best possible support for your legal
          needs.
        </faq-item>
      </carousel>
    </div>

    <div
      class="section tesimonials-section"
      style="position: relative; z-index: 10;"
    >
      <h2>Testimonials</h2>

      <div class="block tesimonials-block">
        <p>
          We have received exceptionally positive feedback from numerous law
          firms. However, in line with our commitment to privacy and discretion,
          we have chosen not to publicly display these testimonials. Rest
          assured, client satisfaction and service excellence are our top
          priorities.
        </p>

        <b style="color: #F8822B; font-size: 24px;"
          >Ready to redefine efficiency and success in your law firm?
          <br />Contact us to learn more about our AI Paralegal</b
        >

        <consultation-button
          text="Book a Demo"
          link="https://www.immigrationlaw.ai/activity/ailaw/demo"
        />
      </div>

      <div class="foot-img w-100" />
    </div>
  </div>
</template>

<script>
import HeroParalegal from '@/components/Section/HeroParalegal.vue'
import SplitCarousel from '@/components/split-carousel.vue'
import ds160Cover from '@/assets/immigration/ds160-260-auto-filing.png'
import busCover from '@/assets/immigration/business-management.png'
import ContentCard from '@/components/content-card.vue'
import calendarIcon from '@/assets/paralegal/calendar.png'
import plusIcon from '@/assets/paralegal/plus.png'
import searchIcon from '@/assets/paralegal/search.png'
import noHumanIcon from '@/assets/paralegal/no-human.png'
import efficiencyIcon from '@/assets/paralegal/efficiency.png'
import improvementIcon from '@/assets/paralegal/improvement.png'
import competitiveIcon from '@/assets/paralegal/competitive.png'
import FeatureItem from './feature-item.vue'
import ServiceOfferingItem from './service-offering-item.vue'
import StepItem from './step-item.vue'
import Testimonial from '@/components/Block/Testimonial.vue'
import FaqItem from './faq-item.vue'
import ConsultationButton from '@/components/Block/ConsultationButton.vue'
import Carousel from '@/components/Carousel.vue'

export default {
  components: {
    HeroParalegal,
    SplitCarousel,
    ContentCard,
    FeatureItem,
    ServiceOfferingItem,
    StepItem,
    Testimonial,
    FaqItem,
    ConsultationButton,
    Carousel,
  },
  computed: {
    splitCarouselSource: () => [
      {
        cover: ds160Cover,
        content:
          'Addressing these areas demands a significant investment of time—a resource more precious than ever. Our AI Paralegal Service, designed expressly for contemporary immigration law firms like yours, offers a solution. By automating routine tasks and enhancing case management with AI technology, all under the oversight of experienced paralegals, we ensure your caseload is managed more efficiently and effectively. This strategy frees up your time to concentrate on firm growth and cultivating strategic client relationships.',
      },
      {
        cover: busCover,
        content:
          'Embracing innovative marketing strategies and strengthening your online presence through positive reviews will place your firm in an advantageous position for competitive success in the future.',
      },
    ],
  },
  data: () => ({
    calendarIcon,
    plusIcon,
    searchIcon,
    noHumanIcon,
    efficiencyIcon,
    improvementIcon,
    competitiveIcon,
  }),
}
</script>

<style scoped>
.block {
  text-align: center;
  padding: 0 2rem;
}

.block > p {
  margin-top: 2rem;
}

.section {
  padding: 4rem 0;
}

.section > h2 {
  text-align: center;
  font-size: 24px;
}

.intro {
  height: 24rem;
}

.block b,
.block p {
  font-size: 16px;
}

.sider {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.sider p {
  text-align: left;
}

.illustration img {
  width: 12rem;
}

.illustration > div {
  font-weight: bold;
}

.work-with-us {
  display: flex;
}

.work-with-us > * {
  flex: 1;
  margin: 2rem;
}

.features {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .offerings {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .offering-modal {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.offerings {
  display: grid;
  gap: 2rem;
  align-items: stretch;
  margin-top: 2rem;
}

.offering-modal {
  display: grid;
  padding: 4rem;
  gap: 2rem;
}

.tesimonials-block > * {
  margin-top: 4rem;
}

.foot-img {
  position: absolute;
  z-index: -1;
  bottom: 0;
  background-image: url('../../assets/nxt/footer.png');
  min-height: 10rem;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.block3 {
  background: url(../../assets/immigration/middle.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 40px 0 40px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .intro {
    height: 16rem;
  }

  .section > h2 {
    font-size: 36px;
  }

  .page {
    padding: 6rem;
  }

  .block b,
  .block p {
    font-size: 21px;
  }

  .sider {
    flex-direction: row;
    margin-top: 2rem;
  }

  .features {
    flex-direction: row;
  }

  .offerings {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .tesimonials-section {
    margin-top: -8rem;
  }

  .offering-modal {
    grid-template: repeat(2, 1fr) / repeat(3, 1fr);
  }
}
</style>
