<template>
  <div class="content-card">
    <h5>{{ title }}</h5>
    <p>{{ content }}</p>
    <img :src="icon" alt="icon" class="icon" />
  </div>
</template>

<script>
export default {
  props: ['title', 'content', 'icon'],
}
</script>

<style scoped>
.content-card {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  position: relative;
}

.content-card > .icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 50%, 0); 

  width: 4rem;
  height: 4rem;
}
</style>
