<template>
  <div class="split-carousel">
    <carousel
      hide-indicators
      carouselId="paralegal-cover"
      class="paralegal-cover"
    >
      <div class="carousel-item cover" v-for="item in source" :key="item.cover">
        <img :src="item.cover" />
      </div>
    </carousel>

    <div id="paralegal-content" class="carousel slide carousel-content">
      <div class="carousel-inner">
        <div
          class="carousel-item content"
          :class="{ active: i === index }"
          v-for="(item, i) in source"
          :key="item.content"
        >
          {{ item.content }}
        </div>
      </div>

      <ol class="carousel-indicators">
        <li
          v-for="(_, i) in source"
          ref="indicators"
          :key="i"
          :class="{ active: i === index }"
        />
      </ol>
    </div>
  </div>
</template>

<script>
import Carousel from './Carousel.vue'
import $ from 'jquery'

export default {
  components: { Carousel },
  props: ['source'],
  data: () => ({ index: 0 }),
  mounted() {
    $('#paralegal-cover').on('slid.bs.carousel', e => {
      this.index = e.to
    })
  },
}
</script>

<style scoped>
.carousel-item.cover {
  padding: 4rem;
}

.carousel-item.cover > img {
  width: 100%;
  height: 100%;
}

.paralegal-cover {
  flex-shrink: 0;
  flex: 1;
  height: 30rem;
}

.split-carousel {
  display: flex;
  flex-direction: column;
}

.carousel-content {
  background: white;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  flex: 1;
  font-size: 18px;
  min-height: 32rem;
}

.carousel-indicators > li {
  background-color: #222;
}

@media screen and (min-width: 768px) {
  .split-carousel {
    flex-direction: row;
  }

  .paralegal-cover {
    height: 100%;
  }

  .carousel-content {
    margin-left: 2rem;
  }
}
</style>
